<template>
  <div v-if="permission.access">
    <div class="w-100 text-right">
      <b-dropdown
        v-if="permission.delete"
        text="Action"
        variant="outline-primary"
        class="mb-2"
        :disabled="!select.selected"
      >
        <b-dropdown-item @click="Delete"> Delete </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="4">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="align-middle text-center">Sl</th>
                  <th>Name</th>
                  <th>Payment Details</th>
                  <th>Commission Details</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                  <th class="align-middle" v-if="permission.delete">
                    <label
                      class="custom-control custom-checkbox mb-0 d-inline-block"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="checkAllDataTables"
                        v-model="selectedAll"
                        @click="selectAll"
                      />
                      <span class="custom-control-label">&nbsp;</span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(course, index) in courses.data"
                  :key="index"
                  v-if="courses.data.length"
                >
                  <td class="align-middle text-center">
                    {{ courses.from + index }}
                  </td>
                  <td>
                    <p class="mb-0"><b>Name : </b>{{ course.name }}</p>
                    <p class="mb-0"><b>Slug : </b>{{ course.slug }}</p>
                    <p class="mb-0" v-if="course.category">
                      <b>Category : </b>{{ course.category.name }}
                    </p>
                  </td>
                  <td class="align-middle">
                    <p class="mb-0">
                      <b>Course Fee : </b>{{ course.course_fee }}/-
                    </p>
                    <p class="mb-0">
                      <b>Discount : </b
                      >{{ course.discount ? course.discount : 0 }}/-
                    </p>
                    <p class="mb-0">
                      <b>Payable Amount : </b
                      >{{ course.payable_amount ? course.payable_amount : 0 }}/-
                    </p>
                  </td>
                  <td class="align-middle">
                    <p class="mb-0">
                      <b>Teacher : </b
                      >{{
                        course.teacher_commission
                          ? course.teacher_commission
                          : 0
                      }}%
                    </p>
                    <p class="mb-0">
                      <b>Affiliate : </b
                      >{{
                        course.affiliate_commission
                          ? course.affiliate_commission
                          : 0
                      }}%
                    </p>
                    <p class="mb-0">
                      <span
                        style="margin-right: 4px"
                        class="badge"
                        :class="
                          course.status == 1 ? 'badge-success' : 'badge-danger'
                        "
                      >
                        {{ course.status == 1 ? "Active" : "Inactive" }}
                      </span>
                      <span
                        class="badge"
                        :class="
                          course.is_show == 1
                            ? 'badge-primary'
                            : 'badge-warning'
                        "
                      >
                        {{ course.is_show == 1 ? "Show" : "Hidden" }}
                      </span>
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    <div class="btn-group">
                      <button
                        v-if="permission.edit"
                        class="btn btn-secondary btn-sm"
                        @click="edit(course.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </button>
                      <router-link
                        :to="'/course/' + course.id"
                        class="btn btn-primary btn-sm"
                      >
                        <feather-icon icon="EyeIcon" />
                      </router-link>
                    </div>
                  </td>
                  <td v-if="permission.delete" class="align-middle">
                    <b-form-checkbox
                      v-model="select.data"
                      :value="course.id"
                      name="flavour-3a"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ courses.from ? courses.from : 0 }} to
                  {{ courses.to ? courses.to : 0 }} of
                  {{ courses.total ? courses.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="courses"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Course'">
          <b-card-text>
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row mb-2">
                  <div class="col-md-12">
                    <b-form-group
                      label="Select Category"
                      label-for="category_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Select Category"
                        rules="required"
                      >
                        <Select
                          v-model="form.category_id"
                          filterable
                          name="category_id"
                          id="category_id"
                          placeholder="Select Category"
                        >
                          <Option
                            :value="category.id"
                            v-for="(category, index) in categories"
                            :key="index"
                            v-if="categories.length"
                            >{{ category.name }}</Option
                          >
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Select Teacher" label-for="teacher_id">
                      <validation-provider
                        #default="{ errors }"
                        name="Select Teacher"
                      >
                        <Select
                          v-model="form.teacher_id"
                          filterable
                          :clearable="true"
                          name="teacher_id"
                          id="teacher_id"
                          placeholder="Select Teacher"
                        >
                          <Option
                            :value="teacher.id"
                            v-for="(teacher, index) in teachers"
                            :key="index"
                            v-if="teachers.length"
                            >{{ teacher.name }}</Option
                          >
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Course Name" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="Course Name"
                        rules="required"
                      >
                        <Input
                          name="name"
                          type="text"
                          v-model="form.name"
                          id="name"
                          placeholder="Enter Course Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Course Slug" label-for="slug">
                      <validation-provider
                        #default="{ errors }"
                        name="Course Slug"
                        rules="required"
                      >
                        <Input
                          name="slug"
                          type="text"
                          v-model="form.slug"
                          id="slug"
                          placeholder="Enter Course Slug"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group label="Course Fee" label-for="course_fee">
                      <validation-provider
                        #default="{ errors }"
                        name="Course Fee"
                        rules="required"
                      >
                        <InputNumber
                          v-model="form.course_fee"
                          :min="0"
                          class="w-100"
                          id="course_fee"
                          name="course_fee"
                          placeholder="Enter Course Fee"
                        ></InputNumber>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group label="Course Discount" label-for="discount">
                      <validation-provider
                        #default="{ errors }"
                        name="Course Discount"
                      >
                        <InputNumber
                          v-model="form.discount"
                          :min="0"
                          :max="form.course_fee"
                          class="w-100"
                          id="discount"
                          name="discount"
                          placeholder="Enter Course Discount"
                        ></InputNumber>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      label="Teacher Commission (%)"
                      label-for="teacher_commission"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Teacher Commission"
                      >
                        <InputNumber
                          v-model="form.teacher_commission"
                          :min="0"
                          class="w-100"
                          id="teacher_commission"
                          name="teacher_commission"
                          placeholder="Enter Teacher Commission"
                        ></InputNumber>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      label="Affiliate Commission (%)"
                      label-for="affiliate_commission"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Affiliate Commission"
                      >
                        <InputNumber
                          v-model="form.affiliate_commission"
                          :min="0"
                          class="w-100"
                          id="affiliate_commission"
                          name="affiliate_commission"
                          placeholder="Enter Affiliate Commission"
                        ></InputNumber>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group label="Select Course Level" label-for="level">
                      <validation-provider
                        #default="{ errors }"
                        name="Select Level"
                        rules="required"
                      >
                        <Select
                          v-model="form.level"
                          filterable
                          name="level"
                          id="level"
                          placeholder="Select Course Level"
                        >
                          <Option :value="1">Beginner</Option>
                          <Option :value="2">Intermediate</Option>
                          <Option :value="3">Advanced</Option>
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group label="Select Course Type" label-for="type">
                      <validation-provider
                        #default="{ errors }"
                        name="Select Type"
                        rules="required"
                      >
                        <Select
                          v-model="form.type"
                          filterable
                          name="type"
                          id="type"
                          placeholder="Select Course Type"
                        >
                          <Option :value="1">Paid</Option>
                          <Option :value="2">Free</Option>
                          <Option :value="3">Special Offer</Option>
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Select Course Activities"
                      label-for="activities"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Select Course Activities"
                      >
                        <Select
                          v-model="form.activities"
                          filterable
                          name="activities"
                          id="activities"
                          placeholder="Select Course Activities"
                        >
                          <Option :value="1">Popular</Option>
                          <Option :value="2">Trendy</Option>
                          <Option :value="3">New</Option>
                          <Option :value="4">Trendy High Demand</Option>
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                  Status
                </b-form-checkbox>
                <b-form-checkbox
                  name="is_show"
                  style="margin-top: 5px"
                  v-model="form.is_show"
                  switch
                  inline
                >
                  Is Show
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  :disabled="disabled"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      selected: [],
      search: {
        category_id: "",
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      form: {
        id: "",
        category_id: null,
        teacher_id: null,
        slug: null,
        name: null,
        course_fee: null,
        discount: null,
        teacher_commission: null,
        affiliate_commission: null,
        level: null,
        type: null,
        activities: null,
        status: false,
        is_show: false,
      },
      disabled: false,
      icon: this.$store.state.base_url + "images/category/default.png",
      courses: {},

      select: {
        employee_id: null,
        data: [],
        selected: false,
      },
      selectedAll: false,

      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.Permission();
    this.getResults();
    this.$store.dispatch("getSubCategory");
    this.$store.dispatch("getTeacher");
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const courses = await this.callApi(
        "post",
        "/app/course/get?page=" + this.search.page,
        this.search
      );
      if (courses.status == 200) {
        this.courses = courses.data.courses;
      }
    },

    Delete() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.DeleteAlert("/app/course/categories/delete", this.select);
          this.select.data = [];
          this.getResults();
        }
      });
    },

    Close() {
      this.form.id = "";
      this.form.category_id = null;
      this.form.teacher_id = null;
      this.form.slug = null;
      this.form.name = null;
      this.form.course_fee = null;
      this.form.discount = null;
      this.form.teacher_commission = null;
      this.form.affiliate_commission = null;
      this.form.level = null;
      this.form.type = null;
      this.form.activities = null;
      this.form.status = false;
      this.form.is_show = false;
      this.disabled = false;
    },

    async edit(id) {
      const course = await this.callApi("post", "/app/course/edit/" + id);
      if (course.status == 200) {
        this.form.id = course.data.course.id;
        this.form.category_id = course.data.course.category_id;
        this.form.teacher_id = course.data.course.teacher_id;
        this.form.name = course.data.course.name;
        this.form.slug = course.data.course.slug;
        this.form.course_fee = course.data.course.course_fee;
        this.form.discount = course.data.course.discount;
        this.form.teacher_commission = course.data.course.teacher_commission;
        this.form.affiliate_commission =
          course.data.course.affiliate_commission;
        this.form.level = course.data.course.level;
        this.form.type = course.data.course.type;
        this.form.activities = course.data.course.activities;
        this.form.status = course.data.course.status == true ? true : false;
        this.form.is_show = course.data.course.is_show == true ? true : false;
      }
    },

    add() {
      this.disabled = true;
      axios
        .post("/app/course/store", this.form)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
          this.Close();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.category_id)
              this.e(e.response.data.errors.category_id[0]);
            if (e.response.data.errors.teacher_id)
              this.e(e.response.data.errors.teacher_id[0]);
            if (e.response.data.errors.slug)
              this.e(e.response.data.errors.slug[0]);
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.course_fee)
              this.e(e.response.data.errors.course_fee[0]);
            if (e.response.data.errors.discount)
              this.e(e.response.data.errors.discount[0]);
            if (e.response.data.errors.teacher_commission)
              this.e(e.response.data.errors.teacher_commission[0]);
            if (e.response.data.errors.affiliate_commission)
              this.e(e.response.data.errors.affiliate_commission[0]);
            if (e.response.data.errors.level)
              this.e(e.response.data.errors.level[0]);
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
            if (e.response.data.errors.activities)
              this.e(e.response.data.errors.activities[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
            if (e.response.data.errors.is_show)
              this.e(e.response.data.errors.is_show[0]);
            this.disabled = false;
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    // Select Data for Delete
    selectAll(event) {
      if (event.target.checked == false) {
        this.select.data = [];
      } else {
        this.select.data = [];
        this.courses.data.forEach((category) => {
          this.select.data.push(category.id);
        });
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "course.access") {
          this.permission.access = true;
        }

        if (permission.slug == "course.create") {
          this.permission.create = true;
        }

        if (permission.slug == "course.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "course.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    "search.category_id": function (val) {
      this.SearchData();
    },
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
    "select.data": function (selected) {
      this.select.selected = selected.length > 0;
      this.selectedAll = selected.length == this.courses.data.length;
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  computed: {
    categories() {
      return this.$store.getters.getSubCategory;
    },
    teachers() {
      return this.$store.getters.getTeacher;
    },
  },
};
</script>

<style></style>
